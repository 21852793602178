import React, {useState} from 'react';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {NextStepButton} from '../../../StepsManager/Components/NextStepButton/NextStepButton';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {classes} from './DeliveryMethodOpen.st.css';
import {DeliveryMethodStepDataHook} from '../DeliveryMethodStep';
import {StatesButtonStates} from 'wix-ui-tpa';

export const DeliveryMethodOpen = () => {
  const localeKeys = useLocaleKeys();
  const {
    checkoutStore: {submitDeliveryMethod},
  } = useControllerProps();
  const [buttonState, setButtonState] = useState(StatesButtonStates.IDLE);

  const submit = () => {
    void submitDeliveryMethod();
    setButtonState(StatesButtonStates.IN_PROGRESS);
  };

  return (
    <div data-hook={DeliveryMethodStepDataHook.open} className={classes.root}>
      <NextStepButton
        onClick={() => void submit()}
        text={localeKeys.checkout.continue_button_label()}
        dataHook={DeliveryMethodStepDataHook.continueButton}
        buttonState={buttonState}
      />
    </div>
  );
};
