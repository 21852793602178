import React, {ComponentType, createContext, useContext, useRef, useState} from 'react';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {FormHandle, FormValues} from '@wix/form-viewer';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {FormViewerHandle, FormError} from '@wix/form-viewer/widget';
import {FullAddressContactDetails, ApiAddress} from '@wix/ambassador-ecom-v1-checkout/types';
import {
  getContactDetailsFromContactFormValues,
  getContactFormInitialState,
} from '../../../../Form/ContactForm/contactForm.utils';
import {getEmailFormInitialState, getEmailFromEmailFormValues} from '../../../../Form/EmailForm/emailForm.utils';
import {
  getAdditionalInfoFormInitialState,
  getCustomFieldFromAdditionalInfoFormValues,
} from '../../../../Form/AdditionalInfoForm/AdditionalInfoForm';
import {
  getAddressFormInitialState,
  getAddressFromAddressFormValues,
} from '../../../../Form/AddressForm/addressForm.utils';
import {SPECS} from '../../../../constants';

export type CustomerDetailsDataContextType = {
  setContactFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
  contactFormValues: FormValues;
  contactFormViewer: React.RefObject<FormHandle>;
  setContactFormErrors: React.Dispatch<React.SetStateAction<FormError[]>>;
  contactFormErrors: FormError[];
  setEmailFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
  emailFormValues: FormValues;
  emailFormViewer: React.RefObject<FormHandle>;
  setEmailFormErrors: React.Dispatch<React.SetStateAction<FormError[]>>;
  emailFormErrors: FormError[];
  setAdditionalInfoFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
  additionalInfoFormValues: FormValues;
  additionalInfoFormViewer: React.RefObject<FormHandle>;
  setAdditionalInfoFormErrors: React.Dispatch<React.SetStateAction<FormError[]>>;
  additionalInfoFormErrors: FormError[];
  setAddressFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
  addressFormValues: FormValues;
  addressFormViewer: React.RefObject<FormHandle>;
  setAddressFormErrors: React.Dispatch<React.SetStateAction<FormError[]>>;
  addressFormErrors: FormError[];
  areFormsValid: () => Promise<boolean>;
  getContactDetailsFormValues: () => FullAddressContactDetails;
  getEmailFormValues: () => string | undefined;
  getCustomFieldFormValues: () => string | undefined;
  getAddressFormValues: () => ApiAddress;
};

export const CustomerDetailsDataContext = createContext({} as CustomerDetailsDataContextType);

export function withCustomerDetailsData<T extends object>(Component: ComponentType<T>) {
  return function Wrapper(props: T) {
    const {experiments} = useExperiments();
    const isFlowEnabled = experiments.enabled(SPECS.UseNewCheckoutInRestOfTheFlows);

    const {
      checkoutStore: {checkout},
      checkoutSettingsStore: {checkoutSettings},
      memberStore: {isMember},
    } = useControllerProps();

    const [emailFormValues, setEmailFormValues] = useState<FormValues>(getEmailFormInitialState(checkout.buyerInfo));

    const [contactFormValues, setContactFormValues] = useState<FormValues>(
      getContactFormInitialState({
        checkoutSettings,
        contact: checkout.shippingDestination?.contact,
        country: checkout.shippingDestination?.address?.country,
      })
    );

    const [additionalInfoFormValues, setAdditionalInfoFormValues] = useState<FormValues>(
      getAdditionalInfoFormInitialState(checkout.customField)
    );

    const [addressFormValues, setAddressFormValues] = useState<FormValues>(
      getAddressFormInitialState(checkoutSettings, checkout.shippingDestination?.address)
    );

    const emailFormViewer = useRef<FormViewerHandle>(null);
    const contactFormViewer = useRef<FormViewerHandle>(null);
    const additionalInfoFormViewer = useRef<FormViewerHandle>(null);
    const addressFormViewer = useRef<FormViewerHandle>(null);

    const [emailFormErrors, setEmailFormErrors] = useState<FormError[]>([]);
    const [contactFormErrors, setContactFormErrors] = useState<FormError[]>([]);
    const [additionalInfoFormErrors, setAdditionalInfoFormErrors] = useState<FormError[]>([]);
    const [addressFormErrors, setAddressFormErrors] = useState<FormError[]>([]);

    const formRefs = [
      contactFormViewer,
      additionalInfoFormViewer,
      ...(!isMember ? [emailFormViewer] : []),
      ...(isFlowEnabled ? [addressFormViewer] : []),
    ];

    const areFormsValid = async () => {
      const areFormsValidArr = await Promise.all(
        formRefs.filter((ref) => !!ref.current).map((ref) => ref.current!.validate())
      );

      return !areFormsValidArr.includes(false);
    };

    const getContactDetailsFormValues = () => {
      return getContactDetailsFromContactFormValues(contactFormValues, checkoutSettings);
    };

    const getEmailFormValues = () => {
      return !isMember ? getEmailFromEmailFormValues(emailFormValues) : checkout.buyerInfo.email;
    };

    const getCustomFieldFormValues = () => {
      return checkoutSettings.customField.show
        ? getCustomFieldFromAdditionalInfoFormValues(additionalInfoFormValues)
        : undefined;
    };

    const getAddressFormValues = () => {
      return getAddressFromAddressFormValues(checkoutSettings, addressFormValues);
    };

    return (
      <CustomerDetailsDataContext.Provider
        value={{
          contactFormValues,
          setContactFormValues,
          contactFormViewer,
          setContactFormErrors,
          contactFormErrors,
          setEmailFormValues,
          emailFormValues,
          emailFormViewer,
          setEmailFormErrors,
          emailFormErrors,
          setAdditionalInfoFormValues,
          additionalInfoFormValues,
          additionalInfoFormViewer,
          setAdditionalInfoFormErrors,
          additionalInfoFormErrors,
          setAddressFormValues,
          addressFormValues,
          addressFormViewer,
          setAddressFormErrors,
          addressFormErrors,
          areFormsValid,
          getContactDetailsFormValues,
          getEmailFormValues,
          getCustomFieldFormValues,
          getAddressFormValues,
        }}>
        <Component {...props} />
      </CustomerDetailsDataContext.Provider>
    );
  };
}

export function useCustomerDetailsData() {
  const {
    contactFormValues,
    setContactFormValues,
    contactFormViewer,
    setContactFormErrors,
    contactFormErrors,
    setEmailFormValues,
    emailFormValues,
    emailFormViewer,
    setEmailFormErrors,
    emailFormErrors,
    setAdditionalInfoFormValues,
    additionalInfoFormValues,
    additionalInfoFormViewer,
    setAdditionalInfoFormErrors,
    additionalInfoFormErrors,
    setAddressFormValues,
    addressFormValues,
    addressFormViewer,
    setAddressFormErrors,
    addressFormErrors,
    areFormsValid,
    getContactDetailsFormValues,
    getEmailFormValues,
    getCustomFieldFormValues,
    getAddressFormValues,
  } = useContext(CustomerDetailsDataContext);

  return {
    contactFormValues,
    setContactFormValues,
    contactFormViewer,
    setContactFormErrors,
    contactFormErrors,
    setEmailFormValues,
    emailFormValues,
    emailFormViewer,
    setEmailFormErrors,
    emailFormErrors,
    setAdditionalInfoFormValues,
    additionalInfoFormValues,
    additionalInfoFormViewer,
    setAdditionalInfoFormErrors,
    additionalInfoFormErrors,
    setAddressFormValues,
    addressFormValues,
    addressFormViewer,
    setAddressFormErrors,
    addressFormErrors,
    areFormsValid,
    getContactDetailsFormValues,
    getEmailFormValues,
    getCustomFieldFormValues,
    getAddressFormValues,
  };
}
