import React from 'react';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {BillingAddressDropdown} from '../BillingAddressDropdown/BillingAddressDropdown';
import {ContactAndAddressSummary} from '../../../../ContactAndAddressSummary/ContactAndAddressSummary';
import {useBillingData} from '../WithBillingData/WithBillingData';

export enum MemberBillingDetailsDataHook {
  root = 'MemberBillingDetails.root',
}

export const MemberBillingDetails = () => {
  const {
    memberStore: {defaultAddress},
  } = useControllerProps();
  const {currentBillingInfo, onSetCurrentBillingInfoChange} = useBillingData();

  if (!currentBillingInfo) {
    // TODO: deal with empty default address
    onSetCurrentBillingInfoChange(defaultAddress!);
  }

  return (
    <div data-hook={MemberBillingDetailsDataHook.root}>
      <BillingAddressDropdown />
      <ContactAndAddressSummary contact={currentBillingInfo?.contact} address={currentBillingInfo?.address} />
    </div>
  );
};
