import React from 'react';
import {classes} from './BillingDetails.st.css';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {BillingAddressTitle} from '../BillingAddressTitle/BillingAddressTitle';
import {MemberBillingDetails} from './MemberBillingDetails';
import {BillingDetailsForm} from './BillingDetailsForm/BillingDetailsForm';
import {BillingSameAsShippingCheckbox} from './BillingSameAsShippingCheckbox';

export enum BillingDetailsDataHook {
  root = 'BillingDetails.root',
}

export const BillingDetails = () => {
  const {
    memberStore: {isMember},
    checkoutStore: {isShippingFlow},
  } = useControllerProps();

  return (
    <div data-hook={BillingDetailsDataHook.root} className={classes.root}>
      <BillingAddressTitle />
      {isShippingFlow && <BillingSameAsShippingCheckbox />}
      {isMember ? <MemberBillingDetails /> : <BillingDetailsForm />}
    </div>
  );
};
