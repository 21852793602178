import React from 'react';
import {DeliveryMethodPicker} from './DeliveryMethodPicker/DeliveryMethodPicker';
import {useControllerProps} from '../Widget/ControllerContext';
import {UnsupportedShippingDestinationError} from './ErrorComponents/UnsupportedShippingDestinationError';

export enum DeliveryMethodSectionDataHook {
  deliveryMethodPicker = 'DeliveryMethodSection.deliveryMethodPicker',
  noShippingMessage = 'DeliveryMethodSection.noShippingMessage',
}

export const DeliveryMethodSection = () => {
  const {deliveryMethodStore} = useControllerProps();
  const {canShipToDestination} = deliveryMethodStore;

  return canShipToDestination ? (
    <DeliveryMethodPicker dataHook={DeliveryMethodSectionDataHook.deliveryMethodPicker} />
  ) : (
    <UnsupportedShippingDestinationError />
  );
};
