import {Text, TextButton, TextPriority} from 'wix-ui-tpa';
import {classes} from './BillingAddressTitle.st.css';
import React from 'react';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../../../Widget/ControllerContext';

export enum BillingAddressTitleDataHook {
  title = 'BillingAddressTitle.title',
  changeButton = 'BillingAddressTitle.changeButton',
}

export const BillingAddressTitle = () => {
  const localeKeys = useLocaleKeys();
  const {
    memberStore: {isMember},
  } = useControllerProps();

  return (
    <>
      <Text
        tagName={'h2'}
        priority={TextPriority.primary}
        data-hook={BillingAddressTitleDataHook.title}
        className={classes.billingAddressTitle}>
        {localeKeys.checkout.billing_information.title()}
      </Text>
      {isMember && (
        <TextButton key={'textButton'} data-hook={BillingAddressTitleDataHook.changeButton}>
          {localeKeys.checkout.change()}
        </TextButton>
      )}
    </>
  );
};
